import { default as iconswg5Tdexqz9Meta } from "/app/pages/about/icons.vue?macro=true";
import { default as Mizu_45And_45TemiuveEjYK3M9Meta } from "/app/pages/about/Mizu-And-Temi.vue?macro=true";
import { default as Application_45SentwTWofD1FtEMeta } from "/app/pages/Application-Sent.vue?macro=true";
import { default as create2w9ZVTxOXrMeta } from "/app/pages/application/create.vue?macro=true";
import { default as _91id_93MLQ2myyuoWMeta } from "/app/pages/fairy/[id].vue?macro=true";
import { default as imprintXQz1X9eYwAMeta } from "/app/pages/imprint.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91id_93IbmJpPiXErMeta } from "/app/pages/pet/[id].vue?macro=true";
import { default as PlacesYqzjiZRbRBMeta } from "/app/pages/Places.vue?macro=true";
import { default as Exam_45Hallp9NGiQ83EtMeta } from "/app/pages/places/Exam-Hall.vue?macro=true";
import { default as Magical_45Tree0gPzWRy107Meta } from "/app/pages/places/Magical-Tree.vue?macro=true";
import { default as PlaygroundHo5HjAfjMIMeta } from "/app/pages/places/Playground.vue?macro=true";
import { default as Rules4U3YeaSGiMMeta } from "/app/pages/places/Rules.vue?macro=true";
import { default as Shopping_45DistrictWFEj5lYnDUMeta } from "/app/pages/places/Shopping-District.vue?macro=true";
import { default as _91id_93MAnjfeDauAMeta } from "/app/pages/places/shopping-district/shops/[id].vue?macro=true";
import { default as SupermarketBBd5uSO857Meta } from "/app/pages/places/Supermarket.vue?macro=true";
import { default as Witch_45ListKI7IoBHPkDMeta } from "/app/pages/places/Witch-List.vue?macro=true";
import { default as ApplicationsFeGC8gTv0tMeta } from "/app/pages/teacher/Applications.vue?macro=true";
import { default as _91id_93NpjD1aGEhZMeta } from "/app/pages/teacher/applications/[id].vue?macro=true";
import { default as ExamsAShbaqTUWBMeta } from "/app/pages/teacher/Exams.vue?macro=true";
import { default as _91id_93WedSUTM2P7Meta } from "/app/pages/teacher/exams/[id].vue?macro=true";
import { default as indexjJXhGMXpFhMeta } from "/app/pages/teacher/index.vue?macro=true";
import { default as To_45Do9n4e6nyFxlMeta } from "/app/pages/To-Do.vue?macro=true";
import { default as Homek6h5Xa3VcjMeta } from "/app/pages/user/Home.vue?macro=true";
import { default as Login2h7KEdVKOFMeta } from "/app/pages/user/Login.vue?macro=true";
import { default as NewsQc0lt2MSwYMeta } from "/app/pages/user/News.vue?macro=true";
import { default as Week_45PlanCriF0vUZKSMeta } from "/app/pages/Week-Plan.vue?macro=true";
import { default as archivmentsYatsDtit9KMeta } from "/app/pages/witch/[id]/archivments.vue?macro=true";
import { default as inventoryI53imnWwPXMeta } from "/app/pages/witch/[id]/inventory.vue?macro=true";
import { default as profileab2GCtZ3b4Meta } from "/app/pages/witch/[id]/profile.vue?macro=true";
export default [
  {
    name: "about-icons",
    path: "/about/icons",
    component: () => import("/app/pages/about/icons.vue").then(m => m.default || m)
  },
  {
    name: "about-Mizu-And-Temi",
    path: "/about/Mizu-And-Temi",
    component: () => import("/app/pages/about/Mizu-And-Temi.vue").then(m => m.default || m)
  },
  {
    name: "Application-Sent",
    path: "/Application-Sent",
    component: () => import("/app/pages/Application-Sent.vue").then(m => m.default || m)
  },
  {
    name: "application-create",
    path: "/application/create",
    component: () => import("/app/pages/application/create.vue").then(m => m.default || m)
  },
  {
    name: "fairy-id",
    path: "/fairy/:id()",
    component: () => import("/app/pages/fairy/[id].vue").then(m => m.default || m)
  },
  {
    name: "imprint",
    path: "/imprint",
    component: () => import("/app/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "pet-id",
    path: "/pet/:id()",
    component: () => import("/app/pages/pet/[id].vue").then(m => m.default || m)
  },
  {
    name: "Places",
    path: "/Places",
    component: () => import("/app/pages/Places.vue").then(m => m.default || m)
  },
  {
    name: "places-Exam-Hall",
    path: "/places/Exam-Hall",
    component: () => import("/app/pages/places/Exam-Hall.vue").then(m => m.default || m)
  },
  {
    name: "places-Magical-Tree",
    path: "/places/Magical-Tree",
    component: () => import("/app/pages/places/Magical-Tree.vue").then(m => m.default || m)
  },
  {
    name: "places-Playground",
    path: "/places/Playground",
    component: () => import("/app/pages/places/Playground.vue").then(m => m.default || m)
  },
  {
    name: "places-Rules",
    path: "/places/Rules",
    component: () => import("/app/pages/places/Rules.vue").then(m => m.default || m)
  },
  {
    name: "places-Shopping-District",
    path: "/places/Shopping-District",
    component: () => import("/app/pages/places/Shopping-District.vue").then(m => m.default || m)
  },
  {
    name: "places-shopping-district-shops-id",
    path: "/places/shopping-district/shops/:id()",
    component: () => import("/app/pages/places/shopping-district/shops/[id].vue").then(m => m.default || m)
  },
  {
    name: "places-Supermarket",
    path: "/places/Supermarket",
    component: () => import("/app/pages/places/Supermarket.vue").then(m => m.default || m)
  },
  {
    name: "places-Witch-List",
    path: "/places/Witch-List",
    component: () => import("/app/pages/places/Witch-List.vue").then(m => m.default || m)
  },
  {
    name: "teacher-Applications",
    path: "/teacher/Applications",
    component: () => import("/app/pages/teacher/Applications.vue").then(m => m.default || m)
  },
  {
    name: "teacher-applications-id",
    path: "/teacher/applications/:id()",
    component: () => import("/app/pages/teacher/applications/[id].vue").then(m => m.default || m)
  },
  {
    name: "teacher-Exams",
    path: "/teacher/Exams",
    component: () => import("/app/pages/teacher/Exams.vue").then(m => m.default || m)
  },
  {
    name: "teacher-exams-id",
    path: "/teacher/exams/:id()",
    component: () => import("/app/pages/teacher/exams/[id].vue").then(m => m.default || m)
  },
  {
    name: "teacher",
    path: "/teacher",
    component: () => import("/app/pages/teacher/index.vue").then(m => m.default || m)
  },
  {
    name: "To-Do",
    path: "/To-Do",
    component: () => import("/app/pages/To-Do.vue").then(m => m.default || m)
  },
  {
    name: "user-Home",
    path: "/user/Home",
    component: () => import("/app/pages/user/Home.vue").then(m => m.default || m)
  },
  {
    name: "user-Login",
    path: "/user/Login",
    component: () => import("/app/pages/user/Login.vue").then(m => m.default || m)
  },
  {
    name: "user-News",
    path: "/user/News",
    component: () => import("/app/pages/user/News.vue").then(m => m.default || m)
  },
  {
    name: "Week-Plan",
    path: "/Week-Plan",
    component: () => import("/app/pages/Week-Plan.vue").then(m => m.default || m)
  },
  {
    name: "witch-id-archivments",
    path: "/witch/:id()/archivments",
    component: () => import("/app/pages/witch/[id]/archivments.vue").then(m => m.default || m)
  },
  {
    name: "witch-id-inventory",
    path: "/witch/:id()/inventory",
    component: () => import("/app/pages/witch/[id]/inventory.vue").then(m => m.default || m)
  },
  {
    name: "witch-id-profile",
    path: "/witch/:id()/profile",
    component: () => import("/app/pages/witch/[id]/profile.vue").then(m => m.default || m)
  }
]